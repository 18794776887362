//@import '@angular/material/theming';
//
//@mixin mat-ripple-theme($theme) {
//  .mat-ripple-element {
//    background-color: rgba(224, 19, 19, 0.4);
//  }
//}

.mat-select-panel.neutron-field-selection {
  max-height: 500px;
}

.mat-option, .mat-select-value {
  color: rgb(55 65 81);
}

.neutron-field-selection .mat-option {
  height: 30px !important;
}

.neutron-field-selection {
  @apply rounded-md;
}

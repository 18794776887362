/* output: main.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        background-color: #fff;
        @apply text-gray-700 text-[16px];
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: button;
        width: 5px;
        height: 5px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

mjx-container {
    @apply hover:border-gray-200 border border-transparent
}

/* lato-100 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-100italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-300 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-300italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/lato/lato-v22-latin-ext_latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v22-latin-ext_latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-200 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-300 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-regular - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-500 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-600 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-700 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-800 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-900 - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-200italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-300italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-500italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-600italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-700italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-800italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* crimson-pro-900italic - latin-ext_latin */
@font-face {
    font-family: 'Crimson Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local(''),
    url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/crimson/crimson-pro-v23-latin-ext_latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

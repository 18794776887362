.settings-dialog {
  box-shadow: rgba(15, 15, 15, 0.05) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 5px 10px, rgba(15, 15, 15, 0.2) 0 15px 40px;
  border-radius: 5px;
  margin: 0;
  overflow: hidden;
  width: 1150px;
  max-width: calc(-100px + 100vw) !important;
  height: calc(-100px + 100vh);
  max-height: 715px;
  @apply lp:max-h-[650px] lp:w-[1000px] ip:max-h-[800px]
}

@media (max-width: 500px) {
  .settings-dialog {
    max-width: calc(-20px + 100vw) !important;
    height: 85vh;
  }
}

.settings-dialog .mat-dialog-container {
  padding: 0;
}

button {
  @apply focus:outline-none
}

.link {
  @apply hover:underline text-blue-900 max-w-fit
}

/* Customise the style of Account menu and Dash */
.AccountMenuPanel, .DashPanel {
    margin-top: 5px;
    max-width: 630px !important;
    @apply rounded-md !important;
}

@media (max-width: 500px) {
  .DashPanel {
    width: calc(-25px + 100vw) !important;
  }

}

.DashMenuPanel {
  max-width: 650px !important;
}

/* disable button overlay being opaque */
.mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-button {
  min-width: min-content !important;
}

.no-scroll {
  overflow-y: hidden;
}

.full-screen-modal .mat-dialog-container {
  @apply w-full h-full p-0 border-0 m-0 bg-black/50 rounded-none;
}

mjx-container svg {
  display: inline;
}

mjx-container {
  font-size: 92%;
}

.about-dialog > .mat-dialog-container {
  @apply p-0;
  max-width: calc(-50px + 100vw);
}

.CtxtMenu_MenuItem.CtxtMenu_MenuActive {
  @apply bg-dodger-blue
}

/* contextual menu */
.menuMore {
  @apply rounded-md !important;
}

.snack-bar-success {
  @apply bg-green-100 text-green-900 text-center;
}

.CtxtMenu_Info .CtxtMenu_InfoContent {
  max-width: 768px;
  padding: 1rem 1.5rem;
  margin: 0 auto;
  border: 0;
}

.CtxtMenu_Info .CtxtMenu_InfoContent mjx-container:hover {
  border-color: transparent;
}

.CtxtMenu_Info .CtxtMenu_InfoTitle {
  display: none;
}

.CtxtMenu_InfoSignature {
  font-size: 14px;
}

.CtxtMenu_MenuFrame .CtxtMenu_Info {
  border: 0;
  padding: 0.5rem 0.6rem;
  border-radius: 0.4rem;
}

.CtxtMenu_MenuClose.CtxtMenu_InfoClose {
  display: none;
}
